import React,{useState,useEffect} from 'react'
import { TextField, Button, Typography } from '@material-ui/core';
import { db } from '../../firebase/config';
import {  Modal } from 'react-bootstrap';

function Jobs() {
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [requirements, setRequirements] = useState([]);
    const [niceToHave, setNiceToHave] = useState([]);
    const [term,setTerm]=useState('')
    const [id,setId]=useState('')
    const[info,setInfo]=useState('');
    const [jobs, setjobs] = useState([]);
    const [requirement, setRequirement] = useState([]);
    const [url_name,setUrlName]=useState('')
    const handleInputChange = (event) => {
      const { value } = event.target;
      setRequirements(value.split('\n').map((item) => item.trim()));
    };
    const handleInputChange1 = (event) => {
      const { value } = event.target;
      setNiceToHave(value.split('\n').map((item) => item.trim()));
    };
  
    const getjobs = async () => {
      await db
        .collection("Jobs").orderBy('job_id', 'desc')
        .get()
        .then((res) => {
          if (res.empty) {
            return;
          }
          res.forEach((ele) => {
            setjobs((arr) => [...arr, ele.data()]);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    // console.log(blogs);
    useEffect(() => {
      getjobs();
    }, []);
    const handleFormSubmit = (e) => {
      e.preventDefault();
  
      // Create a new job object
      const job = {
        job_id:id,
        title:title,
        info:info,
        term:term,
        job_name:url_name,
        location:location,
        Requirements:requirements,
        Nicetohave:niceToHave,
      };
      db
      .collection('Jobs').doc(title+'_'+id).set(job)
      
      .then(() => {
        console.log('Job uploaded successfully!');
        // Clear form fields after successful upload
        setTitle('');
        setLocation('');
        setRequirements([]);
        setNiceToHave([]);
        setId('')
        setInfo('');
        setUrlName('')
        setTerm('')
      })
      .catch((error) => {
        console.error('Error uploading job:', error);
      });
  };
  const handleRequirementsChange = (e) => {
    // Split the input value by commas to create an array of requirements
    const requirementsArray = e.target.value.split(',');
    setRequirements(requirementsArray);
  };

  const handleNiceToHaveChange = (e) => {
    // Split the input value by commas to create an array of nice-to-have
    const niceToHaveArray = e.target.value.split(',');
    setNiceToHave(niceToHaveArray);
  };
  const [del_id ,setDel_id]=useState('')
  const deletecase = (e,id)=>{
    e.preventDefault();
    setDel_id(id)
    setShowModal(true)
  }
  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = async () => {
    // Handle delete file action
    try {
      const collectionRef = db.collection('Jobs');
      const querySnapshot = await collectionRef.where('job_id', '==',del_id ).get();

      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });

      console.log('Document(s) deleted successfully.');
    } catch (error) {
      console.error('Error deleting document(s):', error);
    }

    
    setShowModal(false);
    // Perform any additional actions here
  };

  return (
   <>
   <h2 className='Heading_Admin'>Jobs</h2>
     <form style={{display:"flex",flexWrap:"wrap"}} onSubmit={handleFormSubmit}>
        <div><TextField
        label="Job Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <br />
      <br />
      <TextField
        label="Job Location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        required
      />
      <br />
      <br />
      <TextField
        label="term"
        value={term}
        onChange={(e) => setTerm(e.target.value)}
        required
      />
      <br />
      <br />
      <TextField
        label="Job Information"
        value={info}
        onChange={(e) => setInfo(e.target.value)}
        required
      />
      <br />
      <br />
      
      <TextField
        label="job Id"
        value={id}
        onChange={(e) => setId(e.target.value)}
        required
      /> <br />
      <br />
      <TextField
        label="Url name"
        value={url_name}
        onChange={(e) => setUrlName(e.target.value)}
        required
      />
      <br /><br /></div>
      <div style={{marginLeft:"100px"}}>
      <TextField
        label="Job Requirements"
        multiline
        rows={6}
        style={{width:"400px"}}
        variant="outlined"
        onChange={handleInputChange}
      />
      <br />
       <br />
     
       <TextField
        label="Nice to have"
        multiline
        rows={6}
        style={{width:"400px"}}
        variant="outlined"
        onChange={handleInputChange1}
      />
      <br />
<br /> 
      <Button type="submit" variant="contained" color="primary">
        Upload Job
      </Button>
      </div>
      
      
    </form>
    
    <br /><br />
    
    <table className="blog-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Case Studies ID</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map(blog => (
          <tr key={blog.job_id}>
            <td>{blog.title}</td>
            <td>{blog.job_id}</td>
            <td> <Button
                variant="contained"
                color="secondary"
                onClick={(e) =>deletecase(e,blog.job_id)}
              >
                Remove Job
              </Button></td>
          </tr>
        ))}
      </tbody>
    </table>
    <br /><br />
    {/* <Button variant="danger" >Delete File</Button> */}

<Modal show={showModal} onHide={() => setShowModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to delete this file?
    {/* <input type="text" className="form-control mt-3" placeholder="Type 'CONFIRM' to delete" /> */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
    <Button variant="danger" onClick={handleDeleteClick}>Delete</Button>
  </Modal.Footer>
</Modal>
   </>
  )
}

export default Jobs