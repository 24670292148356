import React from 'react'
import styles from "./solutions.module.scss";

import iga from "./images/iga.png";
import pam from "./images/pam.png";
import eam from "./images/eam.png";
import ciam from "./images/ciam.png";

import { NavLink } from 'react-router-dom';

const Solutions = () => {
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <NavLink  to="/solutions/identity-governance-and-administration" className={styles.section1Container}>
                    <div className={styles.iconContainer}>
                        <img src={iga} alt="iga" />
                    </div>
                    <div className={styles.textContainer}>
                        Identity Governance and Administration
                    </div>
                </NavLink>

                <div className={styles.hr}></div>

                <NavLink  to="/solutions/privileged-access-management" className={styles.section2Container}>
                    <div className={styles.iconContainer}>
                        <img src={pam} alt="pam" />
                    </div>
                    <div className={styles.textContainer}>
                        Privileged Access Management
                    </div>
                </NavLink>
            </div>

            <div className={styles.divider}></div>

            <div className={styles.rightContainer}>
                <NavLink  to="/solutions/access-management" className={styles.section1Container}>
                    <div className={styles.iconContainer}>
                        <img src={eam} alt="eam" />
                    </div>
                    <div className={styles.textContainer}>
                        Enterprise Access Management
                    </div>
                </NavLink>

                <div className={styles.hr}></div>

                <NavLink  to="/solutions/customer-identity-and-access-management" className={styles.section2Container}>
                    <div className={styles.iconContainer}>
                        <img src={ciam} alt="ciam" />
                    </div>
                    <div className={styles.textContainer}>
                        Customer Identity and Access Management
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default Solutions
