import firebase from 'firebase/compat/app';
import  'firebase/compat/auth';
import 'firebase/compat/analytics'
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAcGVxY4kIrI8EPLSh6jArYImWqyY4_lck",
    authDomain: "sharpits-f9cb4.firebaseapp.com",
    projectId: "sharpits-f9cb4",
    storageBucket: "sharpits-f9cb4.appspot.com",
    messagingSenderId: "931235611197",
    appId: "1:931235611197:web:d5bc9871d15661f9e6a054",
    measurementId: "G-HS3JFNKZGH"
  };

   
// const firebaseApp=firebase.initializeApp(firebaseConfig);
// export const db=firebase.firestore();
//  export const auth =firebaseApp.auth();



   
const firebaseApp=firebase.initializeApp(firebaseConfig);
export const db=firebase.firestore();
 export const auth =firebaseApp.auth();
 
 export const storage = getStorage(firebaseApp);

