import React, { useState, useEffect } from "react";
import { TextField, Button, Typography } from "@material-ui/core";
import { db } from "../../firebase/config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase/config";
import Newsletter from "./Newsletter";
import firebase from 'firebase/compat/app';
function Events() {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [name, setname] = useState("");
  const [info, setinfo] = useState("");
  const [send1, setSend1] = useState("");
  const [showmsg1, setshowmsg1] = useState(false);
  const [token, setToken] = useState("");
  const [eventId, setEventId] = useState("");
  const [showsubmit,setshowsubmit]=useState(true)
  const [candidates,setcandidates]=useState([])
  const [contact,setContact]=useState([])
  const FetchlatestEvent=async(e)=>{
   e.preventDefault();
   setshowsubmit(false)

   await db
   .collection("Events")
   .where("status", "==", true)
   .get()
   .then((res) => {
     if (res.empty) {
       return;
     }
     res.forEach((ele) => {
       console.log(ele.data());
      //  setdata(ele.data());
      const data=ele.data();
      setname(data.EventName)
      setImage(data.EventImage)
      setToken(data.startToken)
      setEventId(data.eventId)
      setinfo(data.EventInfo)
      setcandidates(data.candidates)


     });
   })
   .catch((err) => {
     console.log(err);
   });
  }
  const handleFormSubmit = (e) => {

    e.preventDefault();
    const contactData = [];
    const data=[]
    // const newdata=[];
    db.collection("Events")
    .where("status", "==", true)
    .get()
    .then((querySnapshot) => {
      const batch = db.batch();
      querySnapshot.forEach((doc) => {
        const docRef = doc.ref;
        batch.update(docRef, { status: false });
      });
      return batch.commit();
    }).then(async()=>{
     

      await db
      .collection("contactus")
      .get()
      .then((res) => {
        if (res.empty) {
          return;
        }
        res.forEach((ele,index) => {
            // console.log(ele.data());
            contactData.push(ele.data())
          
            setContact((arr) => [...arr, ele.data()]);
        });
        // console.log(contactData,'dsfjjj')

      })
    }).then(()=>{
     

      contactData.map((c, index) => {
        const tokenInt = parseInt(token); // Convert token to an integer
        const combinedToken = tokenInt + index; // Perform the addition
       console.log(tokenInt,combinedToken,index,token)
        data.push( {
          companyName: c.company,
          email: c.email,
          emailCampaign: true, // Corrected typo
          phone: c.phoneNumber, // Ensure the correct field name
          firstName: c.firstName,
          lastName: c.lastName, // Corrected casing
          newsLetter: true,
          jobTitle: c.jobTitle, // Corrected casing
          token: combinedToken, // Use the combined token
        })
      });
      // setToken(token + contactData.length);
      // setcandidates(newdata);
  
    })
    .then(() => {
      // console.log(candidates,'uhyughyufytftr')
      db.collection("Events")
      .doc(eventId)
      .set({
        EventImage: image,
        EventInfo: info,
        EventName: name,
        startToken: token,
        eventId: eventId,
        status:true,
        candidates:[],
      })
      .then(() => {
        // console.log(newdata)
        setImage("");
        setinfo("");
        setname("");
        setEventId('');
        setToken('')
      })
      .catch((err) => {
        console.log(err);
      });
    })
    .catch((err) => {
      console.log(err);
    });
    return
  
  };
  const updatelatestEvent=(e)=>{
e.preventDefault()
db.collection("Events")
.doc(eventId)
.set({
  EventImage: image,
  EventInfo: info,
  EventName: name,
  startToken: token,
  eventId: eventId,
  status:true,
  candidates:candidates
})
.then(() => {
  setImage("");
  setinfo("");
  setname("");
  setEventId('');
  setToken('')
  setshowsubmit(true)
})
.catch((err) => {
  console.log(err);
});
  }
  const [showmsg, setshowmsg] = useState(false);

  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();
    const file = e.target[0]?.files[0];
    if (!file) return;
    const storageRef = ref(storage, `case_studies/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setSend1("Something get wrong");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
          setSend1("Uploaded");
          setshowmsg(true);
          console.log(downloadURL);
          setImage(downloadURL);
        });
      }
    );
  };
  return (
    <>
      <h2 className="Heading_Admin"> Events</h2>
      <div>
        <Typography variant="subtitle2">Upload small image</Typography>
        <form onSubmit={handleSubmit} className="form">
          <input type="file" />
          <button type="submit">Upload</button>
          {setshowmsg1 ? <p style={{ color: "red" }}>{send1}</p> : ""}
        </form>
        <form
          style={{ display: "flex", flexWrap: "wrap" }}
          onSubmit={handleFormSubmit}
        >
          <div>
            <TextField
              label="Event Image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              required
            />

            <br />
            <br />
            <TextField
              label="Event Name"
              value={name}
              onChange={(e) => setname(e.target.value)}
              required
            />

            <br />
            <br />
            <TextField
              label="Event Information"
              value={info}
              onChange={(e) => setinfo(e.target.value)}
              required
            />
            <br />
            <br />
            <TextField
              label="Event token start"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
            />
            <br />
            <br />
            <TextField
              label="Event Id"
              value={eventId}
              onChange={(e) => setEventId(e.target.value)}
              required
            />

            <br />
            <br />
        {showsubmit && <Button type="submit" variant="contained" color="primary">
              Upload Event
            </Button>}
            
            {showsubmit && <h2 className="Heading_Admin"> Edit current event</h2>} 
         
            {showsubmit &&  <button onClick={FetchlatestEvent} variant="contained" color="primary">
              Update Event
            </button>} 
            {!showsubmit &&  <button onClick={updatelatestEvent} variant="contained" color="primary">
              Update Event
            </button>} 
           

          </div>
        </form>
      </div>
    </>
  );
}

export default Events;
