import {lazy,Suspense} from 'react'
import './App.css';
import { useEffect } from 'react';
import { Routes, Route, NavLink } from "react-router-dom";
import Copyright from './components/footer/Copyright';
import Header from "./components/New folder/header";
import Footer from "./components/footer";
//Admin
import CookieConsent from 'react-cookie-consent';
import Admin from './pages/Admin/Admin'
import Admin_login from './pages/Admin/Admin_login';
import Cookies from 'js-cookie';
import Axios from 'axios'
import { db } from './firebase/config';

//import Home from './pages/home';
// import All_casestudies from './components/Resources/Case_studies/All_casestudies';
// solutions
// import IGA from "./pages/solutions/iga";
// import PAM from "./pages/solutions/pam";
// import EAM from "./pages/solutions/eam";
// import CIAM from "./pages/solutions/ciam";
// import Career_detail from './components/company/career/Career_detail';
const IGA =lazy(()=>import('./pages/solutions/iga'));
const PAM =lazy(()=>import('./pages/solutions/pam'));
const EAM =lazy(()=>import('./pages/solutions/eam'));
const CIAM =lazy(()=>import('./pages/solutions/ciam'));
const Career_detail =lazy(()=>import('./components/company/career/Career_detail'));
const All_casestudies =lazy(()=>import('./components/Resources/Case_studies/All_casestudies'));
// capabilities
// import Capabilities from "./pages/capabilities";
// import Casestudies_detail from './components/Resources/Case_studies/Casestudies_detail';
// import Blog_detail from './components/Resources/Blogs/Blog_details'
const Capabilities =lazy(()=>import('./pages/capabilities'));
const Blog_detail =lazy(()=>import('./components/Resources/Blogs/Blog_details'));
const Casestudies_detail =lazy(()=>import('./components/Resources/Case_studies/Casestudies_detail'));
const Brochures =lazy(()=>import('./components/Resources/Brochures/Brochures'))
const AllBrochures =lazy(()=>import('./components/Resources/Brochures/All_brochures'))


// companies
// import About from "./components/company/About/About";
// import WhySharpITS from "./components/company/Whysharpits/WhySharpITS";
// import Partners from './components/company/Partners/Partners';
// import Career from "./components/company/career/Career";
// import Whitepaper from './components/Resources/Whitepaper/Whitepaper';
// import WhitepaperDetail from './components/Resources/Whitepaper/WhitepaperDetail';
const About =lazy(()=>import('./components/company/About/About'));
const WhySharpITS =lazy(()=>import('./components/company/Whysharpits/WhySharpITS'));
const Partners =lazy(()=>import('./components/company/Partners/Partners'));
const Career =lazy(()=>import('./components/company/career/Career'));
const Whitepaper =lazy(()=>import('./components/Resources/Whitepaper/Whitepaper'));
const WhitepaperDetail =lazy(()=>import('./components/Resources/Whitepaper/WhitepaperDetail'));
const Privacy =lazy(()=>import('./components/company/Privacy/Privacy'))
// contact us
// import ContactUs from './pages/contactUs';
// import All_blog from './components/Resources/Blogs/All_blog';
// import IAM from './components/services/IAM';
// import PS from './components/services/PS';
// import MS from './components/services/MS';
// import SA from './components/services/SA';
// import AA from './components/services/AA';

const Home =lazy(()=>import('./pages/home'));
const ContactUs =lazy(()=>import('./pages/contactUs'));
const All_blog =lazy(()=>import('./components/Resources/Blogs/All_blog'));
const IAM =lazy(()=>import('./components/services/IAM'));
const PS =lazy(()=>import('./components/services/PS'));
const MS =lazy(()=>import('./components/services/MS'));
const SA =lazy(()=>import('./components/services/SA'));
const AA =lazy(()=>import('./components/services/AA'));
const VS =lazy(()=>import('./components/services/VS'));
const Event =lazy(()=>import('./components/Event/Events'));

function App() {
    let mybutton;
    useEffect(() => {
        mybutton = document.getElementById("myBtn");
        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }

        window.onscroll = function () { scrollFunction() };
    }, []);

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    const handleAccept = () => {
        Cookies.set('cookieConsent', 'true', { expires: 365 }); // Set a cookie to indicate consent
        Axios.get("https://ipapi.co/json/")
        .then((response) => {
          let dataa = response.data;
        
          const randomID = generateUniqueID(10);
          const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
      
          const userInfo = {
            id: randomID,
            isMobile: isMobile,
            ipData: dataa,
            date:new Date()
          };
          var encodedData=JSON.stringify(userInfo);
        //   const decodedData = decodeURIComponent(encodedData);
        //   const userData = JSON.parse(decodedData);
          
         // console.log(encodedData);
         Cookies.set('UserInfo', encodedData, { expires: 30 });  
          // Save the case study to Firestore
   db
   .collection('Cookies_data').doc(randomID).set(userInfo).then((response)=>{
return;
   }).catch((error)=>{
    console.log(error);
   })
        })

      };
      function generateUniqueID(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let uniqueID = '';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          uniqueID += characters.charAt(randomIndex);
        }
      
        return uniqueID;
      }
    return (
        <div className="App">
            <div className="header">
                <Header />
            </div>
            <div className="content">
            <Suspense fallback={<h1>Loading...</h1>}>

                {/* <button onClick={topFunction} id="myBtn" title="Go to top">Top</button> */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />

                    {/* solutions */}
                    <Route path="/solutions/identity-governance-and-administration" element={<IGA />} />
                    <Route path="/solutions/privileged-access-management" element={<PAM />} />
                    <Route path="/solutions/access-management" element={<EAM />} />
                    <Route path="/solutions/customer-identity-and-access-management" element={<CIAM />} />
                    {/* services */}
                    <Route path="/services/iam-modernization-services" element={<IAM />} />
                    <Route path="/services/iam-professional-services" element={<PS />} />
                    <Route path="/services/iam-managed-services" element={<MS />} />
                    <Route path="/services/staff-augementation" element={<SA />} />
                    <Route path="/services/iam-advisory-and-assessment-services" element={<AA />} />
                    <Route path="/services/v-ciso" element={<VS />} />
                    <Route path="/event" element={<Event />} />



                    {/* capabilities */}
                    <Route path="/capabilities" element={<Capabilities />} />

                    {/* companies */}
                    <Route path="/company/about-us" element={<About />} />
                    <Route path="/company/why-sharpits" element={<WhySharpITS />} />
                    <Route path="/company/our-partners" element={<Partners />} />
                    <Route path="/company/careers" element={<Career />} />
                    <Route path="/company/contact-us" element={<ContactUs />} />
                    <Route path='/company/career-detail' element={<Career_detail/>}/>
                    <Route path='/company/privacy' element={<Privacy/>}/>
                     {/* Resources */}
                     <Route path="/resources/blogs" element={<All_blog />} />
                    <Route path='/resources/case-studies' element={<All_casestudies/>}/>
                    <Route path='/resources/case-studies-detail' element={<Casestudies_detail/>}/>
                    <Route path='/resources/blog-detail' element={<Blog_detail/>}/>
                    <Route path='/resources/whitepaper' element={<Whitepaper/>}/>
                    <Route path='/resources/whitepaper-download' element={<WhitepaperDetail/>}/>
                    <Route path='/resources/brochures-download' element={<Brochures/>}/>
                    <Route path='/resources/brochures' element={<AllBrochures/>}/>

                    {/* contact us */}
                    <Route path="/contact-us" element={<ContactUs />} />
                    {/* Admin */}
                    <Route path="/admin" element={<Admin />} />
                    <Route path='/login' element={<Admin_login/>}/>
                </Routes>
                </Suspense>

            </div>
            <div className="footer">
                <Footer />
            </div>
            <Copyright/>
            <CookieConsent
            onAccept={handleAccept}
      location="bottom"
      buttonText="Accept"
      cookieName="cookieConsent"
      style={{ background: 'rgba(0, 0, 0, 0.8)', animation: 'fadeIn 0.5s' }} // Apply animation style
    >
      SharpITS uses cookies to ensure you get the best experience.
      <NavLink to='/company/privacy?id=cookies' style={{display:"inline",color:"white",float:"right"}}>Cookies policies</NavLink>
    </CookieConsent>
        </div>
    );
}

export default App;





