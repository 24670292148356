import React,{useState,useEffect} from "react";
import styles from "./footer.module.scss";
import logo from './images/colour.webp'
import websiteLogo from "./images/websiteLogo.png";
import { AiOutlineMail } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { db } from "../../firebase/config";
const Footer = () => {
    const [email,setEmail]=useState('');
    const [showtext,setShowtext]=useState('Be the first one to know  about discounts, offers and events')
    const submitEmail=(e)=>{
        e.preventDefault();
        const currentDate = new Date()
       db.collection('Newsletter').doc(email+'_'+currentDate).set({
        email:email
       }).then((rep)=>{
        setShowtext('Thanks for providing your Email');
        setEmail('')
       }).catch(err=>{
        console.log(err);
       })
    }
    const section3Image = websiteLogo; // Set the default image source
    const [imageSrc, setImageSrc] = useState(section3Image);
  
    // Function to change the image source based on screen width
    const changeImageSource = () => {
      if (window.innerWidth >= 600) {
        // Change the image source to a new image when the screen width is 600 pixels or more
        setImageSrc(section3Image);
      } else {
        // Use the default image source when the screen width is less than 600 pixels
        setImageSrc(logo);
      }
    };
  
    // Add an event listener to the window's resize event
    useEffect(() => {
      window.addEventListener("resize", changeImageSource);
  
      // Call the changeImageSource() function initially to set the image source based on the initial screen width
      changeImageSource();
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", changeImageSource);
      };
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.imageRow}>
                <NavLink  to="/home">
                    <img style={{width:"225px"}} src={imageSrc} alt="website logo" />
                </NavLink>
            </div>
            <div className={styles.textRow}>
                <div className={styles.columns}>
                    <h3>Services</h3>
                    <ul>
                     
                        <li>
                            <NavLink  to="/services/iam-modernization-services" className={styles.navLink}>IAM Modernization Services</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/services/iam-professional-services">Professional Services</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/services/iam-managed-services">Managed Services</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/services/iam-advisory-and-assessment-services">Advisory and Assessment</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/services/staff-augementation">Staff Augmentation</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink} to="/services/v-ciso">vCISO</NavLink>
                        </li>
                    </ul>
                </div>
                <div className={styles.columns}>
                    <h3>Solutions</h3>
                    <ul>
                        <li>
                            <NavLink className={styles.navLink}  to="/solutions/identity-governance-and-administration">Identity Governance and Administration</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/solutions/access-management">Enterprise Access Management</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/solutions/privileged-access-management">Privileged Access Management</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/solutions/customer-identity-and-access-management">Customer Identity and Acccess Management</NavLink>
                        </li>
                    </ul>
                </div>
                <div className={styles.columns}>
                    <h3>Company</h3>
                    <ul>
                        <li>
                            <NavLink className={styles.navLink}  to="/company/about-us">About us</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/company/why-sharpits">Why SharpITS</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/company/our-partners">Partners</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/company/careers">Careers</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/company/contact-us">Contact us</NavLink>
                            
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/company/privacy">Privacy</NavLink>
                        </li>
                    </ul>
                </div>
                <div className={styles.columns}>
                    <h3>Resources</h3>
                    <ul>
                        <li>
                            <NavLink className={styles.navLink}  to="/resources/whitepaper">Whitepapers</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/resources/case-studies">Case Studies</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/resources/blogs">Blogs</NavLink>
                        </li>
                        <li>
                            <NavLink className={styles.navLink}  to="/resources/brochures">Brochures</NavLink>
                        </li>
                    </ul>
                </div>
                <div className={`${styles.columns} ${styles.contact}`}>
                    <div className={styles.contactContainer}>
                        <div className={styles.heading}>Newsletter</div>
                        <div className={styles.info}>{showtext }</div>
                        <div className={styles.inputContainer}>
                            <AiOutlineMail className={styles.icon} />
                            <input className={styles.emailInput} value={email} onChange={(e)=>setEmail(e.target.value)} type="text" placeholder="Email" />
                        </div>
                        <div className={styles.submit} onClick={(e)=>submitEmail(e)}>
                            SUBMIT
                        </div>
                    </div>
                    <div className={styles.socialLinksContainer}>
                        <a href="https://www.linkedin.com/company/sharpits/" target="_blank" style={{color:"white"}}><FaLinkedinIn className={styles.link} /></a>
                       
                        <a href="https://www.instagram.com/_sharpits/" target="_blank"> <FaInstagram style={{color:"white"}} className={styles.link}/></a>
                        <a href="https://www.facebook.com/SharpITS1" target="_blank"> <FaFacebookF style={{color:"white"}} className={styles.link} /></a>
                       
                       <a href="https://twitter.com/_SharpITS" target="_blank"><AiOutlineTwitter style={{color:"white"}} className={styles.link} /></a> 
                       <a href="https://youtube.com/@SharpITS" target="_blank" style={{color:"white"}}><BsYoutube className={styles.link} /></a> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
