import React, { useState,useEffect } from 'react';
import { TextField, Button, Typography, Grid } from '@material-ui/core';
import { db } from '../../firebase/config';
import { storage } from '../../firebase/config';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import './Admin.css'
import {  Modal } from 'react-bootstrap';

function Whitepaper() {
    
  const [caseStudies, setCaseStudies] = useState([]);
  const [images, setImages] = useState([null, null]);
  const [title, setTitle] = useState('');
  const [caseId, setCaseId] = useState('');

  const[Info2,setInfo2]=useState('');
  const [t_info,setT_info]=useState('')
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const[whitepaper_name,setwhitepaper_name]=useState('')
  const [white, setWhite] = useState([]);

  const getcase = async () => {
    await db
      .collection("WhitePaper").orderBy('Whitepaper_id', 'desc')
      .get()
      .then((res) => {
        if (res.empty) {
          return;
        }
        res.forEach((ele) => {
          setWhite((arr) => [...arr, ele.data()]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  useEffect(() => {
    getcase();
  }, []);
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Create a new case study object
    const blog = {
      Title:title,
      Whitepaper_id:caseId,
      description: caseStudies,
      info1:t_info,
      info2:Info2,
      pdf_url:pdf,
      Whitepaper_img:imgUrl,
      whitepaper_name:whitepaper_name,

    };

    // Save the case study to Firestore
   db
      .collection('WhitePaper').doc(title+'_'+caseId).set(blog)
      .then(() => {
        console.log('Whitepaper uploaded successfully!');
        // Clear form fields after successful upload
        setCaseStudies([]);
        setTitle('');
        setCaseId('');
        setInfo2('');
        setT_info('');
       setImgUrl('');
       setPDF('');
      })
      .catch((error) => {
        console.error('Error uploading case study:', error);
      });
  };

  const [send1, setSend1] = useState('')
  const [send2, setSend2] = useState('')
  const [showmsg, setshowmsg] = useState(false);
  const [showmsg1, setshowmsg1] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    const file = e.target[0]?.files[0]
    if (!file) return;
    const storageRef = ref(storage, `case_studies/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL)
          setshowmsg(true)
          setSend1('uploaded')
          console.log(downloadURL);
        });
      }
    );
  }
  const [pdf, setPDF] = useState(null);
  const [progresspercent1, setProgresspercent1] = useState(0);

  const handleSubmit1 = (e) => {
    e.preventDefault()
    console.log('runns');
    const file = e.target[0]?.files[0]
    if (!file) return;
    const storageRef = ref(storage, `case_studies/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent1(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setPDF(downloadURL)
          setshowmsg1(true)
          setSend2('uploaded')
          console.log(downloadURL);
        });
      }
    );
  }
  const [del_id ,setDel_id]=useState('')
  const deleteBlog = (e,id)=>{
    e.preventDefault();
    setDel_id(id)
    setShowModal(true)
  }
  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = async () => {
    // Handle delete file action
    try {
      const collectionRef = db.collection('WhitePaper');
      const querySnapshot = await collectionRef.where('Whitepaper_id', '==',del_id ).get();

      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });

      console.log('Document(s) deleted successfully.');
    } catch (error) {
      console.error('Error deleting document(s):', error);
    }

    
    setShowModal(false);
    // Perform any additional actions here
  };
  return (
   <>
   <h2 className='Heading_Admin'>Whitepaper</h2>
   <Grid item xs={12}>
          <Typography variant="subtitle2">Upload small image</Typography>
          <form onSubmit={handleSubmit} className='form'>
        <input   type='file' />
        <button type='submit'>Upload</button>
        {
          (showmsg)?(
            <p style={{color:"red"}}>{send1}</p>
          ):('')
        }
      </form>

        </Grid>
        <br />
        <Grid item xs={12}>
          <Typography variant="subtitle2">Upload Whitepaper</Typography>
          <form onSubmit={handleSubmit1} className='form'>
        <input   type='file' />
        <button type='submit'>Upload</button>
        {
          (showmsg1)?(
            <p style={{color:"red"}}>{send2}</p>
          ):('')
        }
      </form>

        </Grid>

        <Grid container spacing={2}>
        <form onSubmit={handleFormSubmit}>
        <Grid item xs={12}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Top information"
            value={t_info}
            onChange={(e) => setT_info(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Secondry Information"
            value={Info2}
            onChange={(e) => setInfo2(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Url name"
            value={whitepaper_name}
            onChange={(e) => setwhitepaper_name(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Whitepaper Id"
            value={caseId}
            onChange={(e) => setCaseId(e.target.value)}
            required
            fullWidth
          />
        </Grid>
 <br />
 <br />

        <Button type="submit" variant="contained" color="primary">
        Upload Whitepaper
      </Button>
        </form>
        </Grid>

        <br />
  <br />
    <table className="blog-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Whitepaper ID</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {white.map(blog => (
          <tr key={blog.Whitepaper_id}>
            <td>{blog.Title}</td>
            <td>{blog.Whitepaper_id}</td>
            <td> <Button
                variant="contained"
                color="secondary"
                onClick={(e) =>deleteBlog(e,blog.Whitepaper_id)}
              >
                Remove Whitepaper
              </Button></td>
          </tr>
        ))}
      </tbody>
    </table>
    <br /><br />
    {/* <Button variant="danger" >Delete File</Button> */}

<Modal show={showModal} onHide={() => setShowModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to delete this file?
    {/* <input type="text" className="form-control mt-3" placeholder="Type 'CONFIRM' to delete" /> */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
    <Button variant="danger" onClick={handleDeleteClick}>Delete</Button>
  </Modal.Footer>
</Modal>
   </>
  )
}

export default Whitepaper