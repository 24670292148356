import React, { useEffect } from 'react'
import { db } from '../../firebase/config';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Papa from 'papaparse';

function Contact() {
    const [data, setdata] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    const handleFilter = () => {
      if (startDate && endDate) {
        const filtered = data.filter(item => {
          const itemDate = moment(item.createdAt);
          console.log()
          return itemDate.isBetween(startDate, endDate, undefined, '[]');
        });
        setFilteredData(filtered);
      }
    };
    useEffect(() => {
        db.collection('contactus').get().then((res) => {
            if (res.empty) {
                return;
            }
            res.forEach((ele) => {
                setdata((arr) => [...arr, ele.data()]);
                setFilteredData((arr) => [...arr, ele.data()])
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }, [])
    console.log(data)
 const [detail ,setDetail]=useState('');

    const Showdetail=(e,list)=>{
e.preventDefault();
console.log('run');

setDetail(list);
    }
    const handleDownload = () => {
        const csvData = filteredData.map(({ id, date, ...rest }) => rest);
        downloadCSV(csvData, 'ContactUs.csv');
      };
      const downloadCSV = (data, filename) => {
        const fields = ['firstName', 'lastName', 'email', 'phoneNumber', 'country', 'jobTitle', 'company'];
  
        const reorderedData = data.map(item => {
          return {
            FirstName: item.firstName,
            LastName: item.lastName,
            email: item.email,
            phoneNumber: item.phoneNumber,
            country: item.country,
            JobTitle: item.jobTitle,
            company: item.company,
          };
        });
        
        const csv = Papa.unparse(reorderedData, { fields });
        
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return (
        <>
        <div className="date-range-filter">
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
        className="date-picker"
      />
      <DatePicker
        selected={endDate}
        onChange={date => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText="End Date"
        minDate={startDate}
        className="date-picker"
      />
      <button onClick={handleFilter} className="filter-button">Filter</button>
      <button onClick={handleDownload} className="download-button">Download CSV</button>

    </div>

        <div style={{display:"flex",width:"100%"}}>
            <div style={{width:"400px"}}>
            <h2>Contact List</h2>
            <div>
                <ul>
                    {
                        filteredData?(
                            filteredData.map(list=>(
                            <li className='contact_link' onClick={(e)=>Showdetail(e,list)}>({list.createdAt.slice(0, 10)}) : {list.email}</li>
                        )

                        )):('')
                        
                    }
                </ul>
            </div>
            </div>
            <div>
                {
                    (detail=='')?(''):(
                        <>
                        <p><b>Name</b> :{detail.firstName} {detail.lastName}</p>
                       <p><b>Country</b>:{detail.country}</p> 
                       <p><b>company</b>:{detail.company}</p>
                       <p><b>email</b>:{detail.email}</p>
                       <p><b>hearfrom</b>:{detail.hearFrom}</p>
                       <p><b>Job</b>:{detail.jobTitle}</p>
                       <p><b>Message</b>:{detail.message}</p>
                       <p><b>Number</b>:{detail.phoneNumber}</p>
                       <p><b>Serveice</b>:{detail.serviceInterest}</p>
                       <p><b>Solution</b>:{detail.solutionInterest}</p>
                        </>
                    )
                }
            </div>
        </div>

        </>
    )
}

export default Contact