import React,{useState,useEffect} from 'react'
import './Admin.css'
import { TextField, Button, Typography, Grid } from '@material-ui/core';
import { db } from '../../firebase/config';
import {  Modal } from 'react-bootstrap';
function Newsletter() {
    const [del_id ,setDel_id]=useState('');
    
  const [news, setNews] = useState([]);

  const getcase = async () => {
    await db
      .collection("Newsletter")
      .get()
      .then((res) => {
        if (res.empty) {
          return;
        }
        res.forEach((ele) => {
            setNews((arr) => [...arr, ele.data()]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  useEffect(() => {
    getcase();
  }, []);
    const deleteBlog = (e,id)=>{
      e.preventDefault();
      setDel_id(id)
      setShowModal(true)
    }
    const [showModal, setShowModal] = useState(false);
  
    const handleDeleteClick = async () => {
      // Handle delete file action
      try {
        const collectionRef = db.collection('Newsletter');
        const querySnapshot = await collectionRef.where('Whitepaper_id', '==',del_id ).get();
  
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
  
        console.log('Document(s) deleted successfully.');
      } catch (error) {
        console.error('Error deleting document(s):', error);
      }
  
      
      setShowModal(false);
      // Perform any additional actions here
    };
  return (
    <>
    <h2 className='Heading_Admin'>Newsletter</h2>
     <br />
    <table className="blog-table">
      <thead>
        <tr>
          <th>Email</th>
        
          <th>Send Mail</th>
        </tr>
      </thead>
      <tbody>
        {news.map(blog => (
          <tr key={blog.Whitepaper_id}>
            <td>{blog.email}</td>
           
            <td> <Button
                variant="contained"
                color="Primary"
               
              >
               Send
              </Button></td>
          </tr>
        ))}
      </tbody>
    </table>
    <br /><br />
    {/* <Button variant="danger" >Delete File</Button> */}

<Modal show={showModal} onHide={() => setShowModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to delete this file?
    {/* <input type="text" className="form-control mt-3" placeholder="Type 'CONFIRM' to delete" /> */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
    <Button variant="danger" onClick={handleDeleteClick}>Delete</Button>
  </Modal.Footer>
</Modal>
    </>
  )
}

export default Newsletter