import React, { useState, useEffect } from "react";
import { db } from "../../firebase/config";
import Papa from "papaparse";

function EventGuest() {
  const [data, setData] = useState();
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase(); // Convert to lowercase for case-insensitive search
    console.log(data.candidates)
    setQuery(value);
    const results = data.candidates.filter((item) => {
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase(); // Concatenate firstname and lastname
      return item.token.toString().includes(value) || fullName.includes(value);
    });
    setFilteredData(results);
  };
  
  const downloadCSV = () => {
    // Define the desired header sequence
    const headerSequence = ["firstName", "lastName", "email", "Phone", "jobTitle", "companyName", "emailCampaign", "newsLetter", "token"];
  
    // Function to rearrange the data
    const rearrangeData = (data, headerSequence) => {
      return data.map(item => {
        let rearrangedItem = {};
        headerSequence.forEach(header => {
          rearrangedItem[header] = item[header];
        });
        return rearrangedItem;
      });
    };
  
    // Rearrange the filteredData
    const rearrangedData = rearrangeData(filteredData, headerSequence);
  
    // Convert to CSV
    const csv = Papa.unparse(rearrangedData);
  
    // Create and download the CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "EventCandidates.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const getDetail = async () => {
    const candidated = [];
    await db
      .collection("Events")
      .where("status", "==", true)
      .get()
      .then((res) => {
        if (res.empty) {
          return;
        }
        res.forEach((ele) => {
          console.log(ele.data());
          setData(ele.data());
          candidated.push(ele.data().candidates);
        });
      })
      .then(() => {
        setFilteredData(...candidated);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllDetails = () => {
    db.collection("Events")
      .get()
      .then((res) => {
        if (res.empty) {
          return;
        }
        res.forEach((ele) => {
          setAllData((arr) => [...arr, ele.data()]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const showallDetails = (e, eventName) => {
    e.preventDefault();
    setShowDetails(true);
    const candidates = [];
    console.log(eventName);
    const eventDetails = allData.find(detail => detail.EventName === eventName);

    if (eventDetails) {
      setData(eventDetails);
      setFilteredData(eventDetails.candidates); // Set candidates array directly
    }
  };
  useEffect(() => {
    // getDetail()
    getAllDetails();
  }, []);
  return (
    <>
      {showDetails ? (
        <div className="guestMainContainer">
          <div className="eventContainer">
            <h2 className="darkHeading">Events Details</h2>
            <div>
              <p className="eventLabel">Event Name :</p>
              <p className="eventInfo">{data?.EventName}</p>
            </div>
            <div>
              <p className="eventLabel">Event Info :</p>
              <p className="eventInfo">{data?.EventInfo}</p>
            </div>
            <div>
              <p className="eventLabel">Start Token :</p>
              <p className="eventInfo">{data?.startToken}</p>
            </div>
            <div>
              <p className="eventLabel">Event Id :</p>
              <p className="eventInfo">{data?.eventId}</p>
            </div>
          </div>
          <div className="eventDetils">
            <h2 className="Heading_Admin"> Event Participants and tokens</h2>
            <button onClick={downloadCSV} className="download-button">
              Download CSV
            </button>

            <div className="search-bar-container">
              <input
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Search by name or token..."
                className="search-input"
              />
              <div className="results">
                {filteredData
                  ? filteredData?.map((item, index) => (
                      <div key={index} className="result-item">
                        <p>
                          <strong>Name:</strong> {item.firstName}{" "}
                          {item.lastName}
                        </p>
                        <p>
                          <strong>Job Title:</strong> {item.jobTitle}
                        </p>
                        <p>
                          <strong>Company:</strong> {item.companyName}
                        </p>
                        <p>
                          <strong>Email:</strong> {item.email}
                        </p>
                        <p>
                          <strong>Phone:</strong> {item.Phone}
                        </p>
                        <p>
                          <strong>Token:</strong> {item.token}
                        </p>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="event-list"  style={{ display: "flex",flexWrap:"wrap", gap: "30px",width:"1000px" }}>
            {allData.map((event, index) => (
              <div
                onClick={(e) => showallDetails(e, event.EventName)}
                style={{ width: "300px", cursor: 'pointer' }}
                className={`event ${event.status ? "active" : "inactive"}`}
                key={index}
              >
                <h2>{event.EventName}</h2>
                <span className="status">
                  {event.status ? "Active" : "Inactive"}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default EventGuest;
