import React,{useState,useEffect} from 'react'
import Blogs from '../../components/Admin/Blogs'
import Jobs from '../../components/Admin/Jobs'
import Case from '../../components/Admin/Case'
import Dashboard from '../../components/Admin/Dashboard'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Contact from '../../components/Admin/Contact'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Newsletter from '../../components/Admin/Newsletter'
import Whitepaper from '../../components/Admin/Whitepaper'
import Brouchers from '../../components/Admin/broucher'
import {useNavigate} from "react-router-dom"
import logo from '../../components/footer/images/websiteLogo.png'
import './Admin.css';
import EventGuest from '../../components/Admin/EventGuest'
import Events from '../../components/Admin/Events'
function Admin() {
    const [Active, setActive] = useState("dash");
    let navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    ;
     useEffect(() => {
    // Check if the user is already logged in (e.g., using local storage or an API call)
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
    console.log('user');
    }else{
      navigate('/login')
    }
  }, []);
  const logout= async (e)=>{
    e.preventDefault()
   
    localStorage.removeItem('user')
    navigate('/login');

  }
 
  return (
    <>
  
    <div className='Admin_main_container'>
        <div className='Admin_sidebare_nav'>
        <ul>
          <li><img style={{width:"190px",marginTop:"10px"}} src={logo} alt="" /></li>
            <p className='dashboard_nav_text' onClick={() => setActive("dash")}>Documentation</p>
            <p className='dashboard_nav_text' onClick={() => setActive("blogs")}>Blogs</p>
            <p className='dashboard_nav_text' onClick={() => setActive("jobs")}>Jobs</p>
            <p className='dashboard_nav_text' onClick={() => setActive("case")}>Case-Studies</p>
            <p className='dashboard_nav_text' onClick={() => setActive("white")}>Whitepaper</p>
            <p className='dashboard_nav_text' onClick={() => setActive("brouchers")}>Brouchers</p>
            <p className='dashboard_nav_text' onClick={() => setActive("news")}>Newsletter</p>
            <p className='dashboard_nav_text' onClick={() => setActive("contact")}>Contact</p>
            <p className='dashboard_nav_text' onClick={() => setActive("events")}>Events</p>
            <p className='dashboard_nav_text' onClick={() => setActive("guest")}>Event Details</p>

            <p className='dashboard_nav_text'  onClick={(e) => logout(e)} > Logout</p>
            </ul>
        </div>
        <div className='Admin_right_section'>
           
 {Active ==="dash" && <Dashboard />} 
  {Active === "blogs" && <Blogs/>}
  {Active === "jobs" && <Jobs />}
  {Active === "case" && <Case />}
  {Active === "white" && <Whitepaper />}
  {Active === "news" && <Newsletter />}
   {Active=== "brouchers" && <Brouchers/>}     
   {Active==='events'&& <Events/>}
   {Active==='contact'&&<Contact/>}
   {Active==='guest' &&<EventGuest/>}
        </div>
    </div>
    </>
  )
}

export default Admin