import React, { useState,useEffect,useRef } from 'react';
import { TextField, Button, Typography, Grid } from '@material-ui/core';
import { db } from '../../firebase/config';
import { storage } from '../../firebase/config';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {  Modal } from 'react-bootstrap';
import JoditEditor from "jodit-react";
import { NavLink } from 'react-router-dom';
function Case() {
    const [caseStudies, setCaseStudies] = useState([]);
    const [images, setImages] = useState([null, null]);
    const [title, setTitle] = useState('');
    const [caseId, setCaseId] = useState('');
    const [details,setDetail]=useState('')
    const [t_info,setT_info]=useState('');
 const [case_studies,setCase]=useState([]);
 const editor = useRef(null);
 const [content, setContent] = useState("");
 const [C_name,setC_name]=useState('');
 const [count,setCount]=useState(true);

 const getcase= async() => {
     setCase([])
     await  db.collection('case_studies').orderBy('case', 'desc').get().then(res=>{
       if(res.empty){
        return ;
       }
       res.forEach(ele=>{
        
        setCase(arr => [...arr, ele.data()]);
       })
    }).catch((err)=>{
        console.log(err);
    })
 
 }
 console.log(case_studies);
 useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
 
useEffect(() => {
    
     getcase();
    
   }, [count]);

    const handleFormSubmit = (e) => {
      e.preventDefault();
  
      // Create a new case study object
      const caseStudy = {
        title:title,
        case:caseId,
        description: caseStudies,
        info:t_info,
        d_image:imgUrl1,
        m_image:imgUrl,
        case_name:C_name,
      };
  console.log(caseStudies);
 
      // Save the case study to Firestore
     db
        .collection('case_studies').doc(title+'_'+caseId)
        .set({...caseStudy,content:content})
        .then(() => {
          console.log('Case study uploaded successfully!');
          // Clear form fields after successful upload
          setCaseStudies([]);
          setImages([null, null]);
          setTitle('');
          setCaseId('');
          setT_info('')
         setImgUrl('')
         setImgUrl1('')
         setContent('')
         setC_name('')
        })
        .catch((error) => {
          console.error('Error uploading case study:', error);
        });
    };
  
   
    const handleAddDescription = () => {
        setCaseStudies([...caseStudies, { heading: '', info: '', description: [] }]);
      };
      const handleDescriptionChange = (index, field, value, detailIndex) => {
      
        const updatedCaseStudies = [...caseStudies];
        if (field === 'details') {
          updatedCaseStudies[index].description[detailIndex] = value;
        } else {
        //   updatedCaseStudies[index].field = value;
        updatedCaseStudies[index][field] = value
        }
        setCaseStudies(updatedCaseStudies);
      };
    const handleRemoveDescription = (index) => {
      const updatedCaseStudies = [...caseStudies];
      updatedCaseStudies.splice(index, 1);
      setCaseStudies(updatedCaseStudies);
    };
  
    // const handleAddDetail = (index) => {
    //   const updatedCaseStudies = [...caseStudies];
    //   updatedCaseStudies[index].details.push('');
    //   setCaseStudies(updatedCaseStudies);
    // };
    const handleAddDetail = (index) => {
        const updatedCaseStudies = [...caseStudies];
        updatedCaseStudies[index].description = [...updatedCaseStudies[index].description, ''];
        setCaseStudies(updatedCaseStudies);
      };
    const handleRemoveDetail = (parentIndex, detailIndex) => {
      const updatedCaseStudies = [...caseStudies];
      updatedCaseStudies[parentIndex].description.splice(detailIndex, 1);
      setCaseStudies(updatedCaseStudies);
    };
  
    const handleImageChange = (index, file) => {
      const updatedImages = [...images];
      updatedImages[index] = file;
      setImages(updatedImages);
    };

    
    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);
    const [send1, setSend1] = useState('')
  const [send2, setSend2] = useState('')
  const [showmsg, setshowmsg] = useState(false);
  const [showmsg1, setshowmsg1] = useState(false)
    const handleSubmit = (e) => {
      e.preventDefault()
      const file = e.target[0]?.files[0]
      if (!file) return;
      const storageRef = ref(storage, `case_studies/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgresspercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
            setshowmsg1(true)
            setSend1('Uploaded')
            console.log(downloadURL);
          });
        }
      );
    }
    const [imgUrl1, setImgUrl1] = useState(null);
    const [progresspercent1, setProgresspercent1] = useState(0);
  
    const handleSubmit1 = (e) => {
      e.preventDefault()
      console.log('runns');
      const file = e.target[0]?.files[0]
      if (!file) return;
      const storageRef = ref(storage, `case_studies/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgresspercent1(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl1(downloadURL)
            setshowmsg(true)
            setSend2('Uploaded')
            console.log(downloadURL);
          });
        }
      );
    }
    const [del_id ,setDel_id]=useState('')
    const deletecase = (e,id)=>{
      e.preventDefault();
      setDel_id(id)
      setShowModal(true)
    }
    const [showModal, setShowModal] = useState(false);
  
    const handleDeleteClick = async () => {
      // Handle delete file action
      try {
        const collectionRef = db.collection('case_studies');
        const querySnapshot = await collectionRef.where('case', '==',del_id ).get();
  
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        }).then(()=>{
          setCount(!count)
        })
  
        console.log('Document(s) deleted successfully.');
      } catch (error) {
        console.error('Error deleting document(s):', error);
      }
  
      
      setShowModal(false);
      // Perform any additional actions here
    };




    const[edit,setEdit]=useState(false);

  const maketrue=async(e,id,name)=>{
    try {
      const collectionRef = db.collection("case_studies");
      const querySnapshot = await collectionRef
        .doc(name+'_'+id)
        .update({
          flag:true
        })
        setCount(!count)
   //   console.log("Document(s) deleted successfully.");
    } catch (error) {
      console.error( error);
    }
  }
  const makeFalse=async(e,id,name)=>{
    try {
      
      const collectionRef = db.collection("case_studies");
      const querySnapshot = await collectionRef
      .doc(name+'_'+id)
        .update({
          flag:false
        })
        setCount(!count)

     

     // console.log("Document(s) deleted successfully.");
    } catch (error) {
      console.error( error);
    }
  }
  const[cid ,setCId]=useState('')
 const  Edit=async(e, id,title)=>{
e.preventDefault()
console.log(id);
setEdit(true)
const collectionRef = db.collection("case_studies");
 collectionRef
  .where("case", "==", id)
  .get().then((querySnapshot)=>{
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      let data=doc.data();
      console.log(data.title);
      setTitle(data.title);
      setT_info(data.info);
      setC_name(data.case_name)
      setCaseId(data.case)
      setContent(data.content)
      setImgUrl(data.m_image);
      setImgUrl1(data.d_image)
      setCId(data.title+'_'+data.case)
      });
  })


 }

 const SubmitEdit=(e)=>{
  e.preventDefault();
  console.log('clicked');
  const blog = {
    title:title,
        case:caseId,
        description: caseStudies,
        info:t_info,
        d_image:imgUrl1,
        m_image:imgUrl,
        content:content,
        case_name:C_name,
  };
  
  // Save the case study to Firestore
  db.collection("case_studies")
    .doc(cid)
    .set({
      ...blog,
      createdBy: "ZgFS2bOvyzRIJi78xdH50FZ4ocL2",
      content: content,
    })
  
    .then(() => {
      console.log("blog study uploaded successfully!");
      // Clear form fields after successful upload
      setCaseStudies([]);
      setImages([null, null]);
      setTitle("");
      setCaseId("");
      setT_info("");
      setImgUrl("");
      setImgUrl1("");
      setContent('')
      setC_name('')
      setEdit(false)
      setCount(!count);
    }).catch((err)=>{
  
    })
   }
  return (
    <>
<h2 className='Heading_Admin'>Case Study</h2>
<Grid item xs={12}>
          <Typography variant="subtitle2">Upload small image</Typography>
          <form onSubmit={handleSubmit} className='form'>
        <input   type='file' />
        <button type='submit'>Upload</button>
        {
            (showmsg1) ? (
              <p style={{color:"red"}}>{send1}</p>
            ) : ('')

          }
      </form>
      <TextField
              label="image url"
              value={imgUrl}
              onChange={(e) => setImgUrl(e.target.value)}
              required
              fullWidth
            />
            
    

        </Grid>
        <br />
        <Grid item xs={12}>
          <Typography variant="subtitle2">Upload large image</Typography>
          <form onSubmit={handleSubmit1} className='form'>
        <input   type='file' />
        <button type='submit'>Upload</button>
        {
            (showmsg) ? (
              <p style={{color:"red"}}>{send2}</p>
            ) : ('')

          }
      </form>
      <TextField
              label="image url"
              value={imgUrl1}
              onChange={(e) => setImgUrl1(e.target.value)}
              required
              fullWidth
            />
        </Grid>
<br />
<br />
         <form onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Top information"
            value={t_info}
            onChange={(e) => setT_info(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Case name"
            value={C_name}
            onChange={(e) => setC_name(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Case ID"
            value={caseId}
            onChange={(e) => setCaseId(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <JoditEditor
              ref={editor}
              value={content}
              // config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
        {/* <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleAddDescription}>
            Add Description
          </Button>
        </Grid> */}
        {/* {caseStudies.map((description, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <TextField
                label="Heading"
                value={description.heading}
                onChange={(e) => handleDescriptionChange(index, 'heading', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Info"
                value={description.info}
                onChange={(e) => handleDescriptionChange(index, 'info', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => handleAddDetail(index)}>
                Add Detail
              </Button>
            </Grid>
            <br />
            <br />

          
            {Array.isArray(description?.description)&&description?.description.map((detail, detailIndex) => (
              <Grid container spacing={2} key={detailIndex}>
                <Grid item xs={12}>
                  <TextField
                    label="Detail"
                    value={detail}
                    onChange={(e) =>
                        handleDescriptionChange(index, 'details',e.target.value, detailIndex)
                    }
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                  />
                 
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveDetail(index, detailIndex)}
                  >
                    Remove Detail
                  </Button>
                </Grid>
              </Grid>
            ))}
            <br />
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleRemoveDescription(index)}
              >
                Remove Description
              </Button>
            </Grid>
          </React.Fragment>
        ))} */}
        <br />
        
      </Grid>
      <br />
      <br />
      {
          (edit)?(    <Button onClick={(e)=>SubmitEdit(e)} variant="contained" color="primary">
          Edit Case Studies
        </Button>):(    <Button type="submit" variant="contained" color="primary">
          Upload Case Studies
        </Button>)
        }
    
    </form>
    <br /><br />
    
    <table className="blog-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Case Studies ID</th>
          <th>Delete</th>
          <th style={{width:"100px"}}>View</th>
            <th style={{width:"100px"}}>Status</th>
            <th style={{width:"100px"}}>Action</th>
            <th style={{width:"100px"}}>Edit</th>
        </tr>
      </thead>
      <tbody>
        {case_studies.map(blog => (
          <tr key={blog.case}>
            <td>{blog.title}</td>
            <td>{blog.case}</td>
            <td> <Button
                variant="contained"
                color="secondary"
                onClick={(e) =>deletecase(e,blog.case)}
              >
                Remove Case
              </Button></td>
              <td>
                <Button  variant="contained"
                  color="primary">
                <NavLink style={{color:"white"}} to={`/resources/case-studies-detail?id=${blog.case_name}`}>Preview</NavLink>

                </Button>
              </td>
              <td>{(blog.flag)?(
                <p > Live</p>
              ):(<p >Draft</p>)}</td>
              <td>{(blog.flag)?(
                <Button
                variant="contained"
                color="secondary"  onClick={(e) => makeFalse(e, blog.case,blog.title)}>Unpublish</Button>
              ):(<Button
                variant="contained"
                class="btn btn-success" onClick={(e) => maketrue(e, blog.case,blog.title)}>Publish</Button>)}</td>
      <td><Button
                variant="contained"
                color="primary"  onClick={(e) => Edit(e, blog.case,blog.title)}>Edit</Button></td>
          </tr>
        ))}
      </tbody>
    </table>
    <br /><br />
    {/* <Button variant="danger" >Delete File</Button> */}

<Modal show={showModal} onHide={() => setShowModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to delete this file?
    {/* <input type="text" className="form-control mt-3" placeholder="Type 'CONFIRM' to delete" /> */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
    <Button variant="danger" onClick={handleDeleteClick}>Delete</Button>
  </Modal.Footer>
</Modal>
    </>
  )
}

export default Case