import React from "react";
import styles from "./services.module.scss";
import icon from "./images/icons8-leadership-50.png";
import innovation from "./images/innovation-1 1 (Traced).png";
import consultation from "./images/consultation 1 (Traced).png";
import vector from "./images/Vector.png";
import techSupport from "./images/tech-support 1 (Traced).png";
import management from "./images/management-service 1 (Traced).png";
import { NavLink } from "react-router-dom";

const Services = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <NavLink
          to="/services/iam-modernization-services"
          className={styles.section1Container}
        >
          <div className={styles.iconContainer}>
            <img src={innovation} alt="innovation" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.headingContainer}>
              IAM Modernization Services
            </div>
            <div className={styles.infoContainer}>
              Modernize your legacy IAM systems, build your next generation IAM
              platform.
            </div>
          </div>
        </NavLink>

        <div className={styles.hr}></div>

        <NavLink
          to="/services/iam-professional-services"
          className={styles.section1Container}
        >
          <div className={styles.iconContainer}>
            <img src={techSupport} alt="tech support" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.headingContainer}>Professional Services</div>
            <div className={styles.infoContainer}>
              Accelerate your IAM Implementation with industry experts.
            </div>
          </div>
        </NavLink>

        <div className={styles.hr}></div>

        <NavLink
          to="/services/iam-managed-services"
          className={styles.section2Container}
        >
          <div className={styles.iconContainer}>
            <img src={consultation} alt="consultation" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.headingContainer}>Managed Services</div>
            <div className={styles.infoContainer}>
              Focus on your business and security needs, let experts manage your
              IAM systems
            </div>
          </div>
        </NavLink>
      </div>

      <div className={styles.divider}></div>

      <div className={styles.rightContainer}>
        <NavLink to="/services/v-ciso" className={styles.section2Container}>
          <div className={styles.iconContainer}>
            <img src={icon} alt="consultation" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.headingContainer}>vCISO</div>
            <div className={styles.infoContainer}>
              Virtual CISO services strengthening cyber resilience{" "}
            </div>
          </div>
        </NavLink>
        <div className={styles.hr}></div>
        <NavLink
          to="/services/staff-augementation"
          className={styles.section3Container}
        >
          <div className={styles.iconContainer}>
            <img src={vector} alt="vector" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.headingContainer}>Staff Augmentation</div>
            <div className={styles.infoContainer}>
              We provide top quality identity experts in all major IAM
              technologies.
            </div>
          </div>
        </NavLink>
        <div className={styles.hr}></div>

        <NavLink
          to="/services/iam-advisory-and-assessment-services"
          className={styles.section2Container}
        >
          <div className={styles.iconContainer}>
            <img src={management} alt="management" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.headingContainer}>
              Advisory and Assessment
            </div>
            <div className={styles.infoContainer}>
              Begin your digital transformation journey with IAM advisory and
              assessment services.
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Services;
