import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { TextField, Button, Typography, Grid } from "@material-ui/core";
import { db } from "../../firebase/config";
import { storage } from "../../firebase/config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import "./Admin.css";
import { Modal } from "react-bootstrap";
import {NavLink} from 'react-router-dom'
function Blogs() {
  const [caseStudies, setCaseStudies] = useState([]);
  const [images, setImages] = useState([null, null]);
  const [title, setTitle] = useState("");
  const [caseId, setCaseId] = useState("");
  const [details, setDetail] = useState("");
  const [t_info, setT_info] = useState("");
  const [b_name, setB_name] = useState("");
  const [blogs, setblogs] = useState([]);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [count,setCount]=useState(true);
  const[bid,setBid]=useState('');
  // const config = useMemo(
  // 	{
  // 		readonly: false, // all options from https://xdsoft.net/jodit/docs/,
  // 		placeholder: 'Start typings...'
  // 	},
  // 	[]
  // );

  const getcase = async () => {
   // console.log('runn');
  setblogs([])
    await db
      .collection("blogs")
      .orderBy("blog_id", "desc")
      .get()
      .then((res) => {
        if (res.empty) {
          return;
        }
        res.forEach((ele) => {
          setblogs((arr) => [...arr, ele.data()]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
 // console.log(blogs);
  useEffect(() => {
    getcase();
  }, [count]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Create a new case study object
    const blog = {
      title: title,
      blog_tittle: title,
      blog_id: caseId,
      // description: caseStudies,
      info: t_info,
      M_img: imgUrl1,
      S_img: imgUrl,
      blog_name: b_name,
    };

    // Save the case study to Firestore
    db.collection("blogs")
      .doc(title + "_" + caseId)
      .set({
        ...blog,
        createdBy: "ZgFS2bOvyzRIJi78xdH50FZ4ocL2",
        content: content,
      })

      .then(() => {
        console.log("blog study uploaded successfully!");
        // Clear form fields after successful upload
        setCaseStudies([]);
        setImages([null, null]);
        setTitle("");
        setCaseId("");
        setT_info("");
        setImgUrl("");
        setImgUrl1("");
        setContent('')
        b_name('')
        setCount(!count);
      })
      .catch((error) => {
        console.error("Error uploading case study:", error);
      });
  };

  const handleAddDescription = () => {
    setCaseStudies([...caseStudies, { title: "", info: "", description: [] }]);
  };
  const handleDescriptionChange = (index, field, value, detailIndex) => {
    const updatedCaseStudies = [...caseStudies];
    if (field === "details") {
      updatedCaseStudies[index].description[detailIndex] = value;
    } else {
      //   updatedCaseStudies[index].field = value;
      updatedCaseStudies[index][field] = value;
    }
    setCaseStudies(updatedCaseStudies);
  };
  const handleRemoveDescription = (index) => {
    const updatedCaseStudies = [...caseStudies];
    updatedCaseStudies.splice(index, 1);
    setCaseStudies(updatedCaseStudies);
  };

  const handleAddDetail = (index) => {
    const updatedCaseStudies = [...caseStudies];
    updatedCaseStudies[index].description = [
      ...updatedCaseStudies[index].description,
      "",
    ];
    setCaseStudies(updatedCaseStudies);
  };
  const handleRemoveDetail = (parentIndex, detailIndex) => {
    const updatedCaseStudies = [...caseStudies];
    updatedCaseStudies[parentIndex].description.splice(detailIndex, 1);
    setCaseStudies(updatedCaseStudies);
  };

  const handleImageChange = (index, file) => {
    const updatedImages = [...images];
    updatedImages[index] = file;
    setImages(updatedImages);
  };

  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [send1, setSend1] = useState("");
  const [send2, setSend2] = useState("");
  const [showmsg, setshowmsg] = useState(false);
  const [showmsg1, setshowmsg1] = useState(false);
  const[edit,setEdit]=useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const file = e.target[0]?.files[0];
    if (!file) return;
    const storageRef = ref(storage, `case_studies/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setSend2("Something get wrong");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
          setSend1("Uploaded");
          setshowmsg(true);
          console.log(downloadURL);
        });
      }
    );
  };
  const [imgUrl1, setImgUrl1] = useState(null);
  const [progresspercent1, setProgresspercent1] = useState(0);
  const handleSubmit1 = (e) => {
    e.preventDefault();
    console.log("runns");
    const file = e.target[0]?.files[0];
    if (!file) return;
    const storageRef = ref(storage, `case_studies/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent1(progress);
      },
      (error) => {
        alert(error);
        setSend2("Something get wrong");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl1(downloadURL);
          setSend2("Uploaded");
          setshowmsg1(true);

          console.log(downloadURL);
        });
      }
    );
  };
  const [del_id, setDel_id] = useState("");
  const deleteBlog = (e, id) => {
    e.preventDefault();
    setDel_id(id);
    setShowModal(true);
  };
  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = async () => {
    // Handle delete file action
    try {
      const collectionRef = db.collection("blogs");
      const querySnapshot = await collectionRef
        .where("blog_id", "==", del_id)
        .get();

      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });
      setCount(!count)

      console.log("Document(s) deleted successfully.");
    } catch (error) {
      console.error("Error deleting document(s):", error);
    }

    setShowModal(false);
    // Perform any additional actions here
  };

  const maketrue=async(e,id,name)=>{
    try {
      const collectionRef = db.collection("blogs");
      const querySnapshot = await collectionRef
        .doc(name+'_'+id)
        .update({
          flag:true
        })
        setCount(!count)
   //   console.log("Document(s) deleted successfully.");
    } catch (error) {
      console.error( error);
    }
  }
  const makeFalse=async(e,id,name)=>{
    try {
      
      const collectionRef = db.collection("blogs");
      const querySnapshot = await collectionRef
      .doc(name+'_'+id)
        .update({
          flag:false
        })
        setCount(!count)

     

     // console.log("Document(s) deleted successfully.");
    } catch (error) {
      console.error( error);
    }
  }
 const  Edit=async(e, id,title)=>{
e.preventDefault()
console.log(id);
setEdit(true)
const collectionRef = db.collection("blogs");
 collectionRef
  .where("blog_id", "==", id)
  .get().then((querySnapshot)=>{
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      let data=doc.data();
      console.log(data.blog_title);
      setTitle(data.title);
      setT_info(data.info);
      setB_name(data.blog_name)
      setCaseId(data.blog_id)
      setContent(data.content)
      setImgUrl(data.S_img);
      setImgUrl1(data.M_img)
      setBid(data.title+'_'+data.blog_id)
      });
  })


 }


 const SubmitEdit=(e)=>{
e.preventDefault();
console.log('clicked');
console.log(bid);
const blog = {
  title: title,
  blog_tittle: title,
  blog_id: caseId,
  // description: caseStudies,
  info: t_info,
  M_img: imgUrl1,
  S_img: imgUrl,
  blog_name: b_name,
};

// Save the case study to Firestore
db.collection("blogs")
  .doc(bid)
  .set({
    ...blog,
    createdBy: "ZgFS2bOvyzRIJi78xdH50FZ4ocL2",
    content: content,
  })

  .then(() => {
    console.log("blog study uploaded successfully!");
    // Clear form fields after successful upload
    setCaseStudies([]);
    setImages([null, null]);
    setTitle("");
    setCaseId("");
    setT_info("");
    setImgUrl("");
    setImgUrl1("");
    setContent('')
    setB_name('')
    setEdit(false)
    setCount(!count);
  }).catch((err)=>{

  })
 }
  return (
    <>
      <h2 className="Heading_Admin">Blogs</h2>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Upload small image</Typography>
        <form onSubmit={handleSubmit} className="form">
          <input type="file" />
          <button type="submit">Upload</button>
          {setshowmsg1 ? <p style={{ color: "red" }}>{send1}</p> : ""}
        </form>
        <TextField
              label="image url"
              value={imgUrl}
              onChange={(e) => setImgUrl(e.target.value)}
              required
              fullWidth
            />
      </Grid>
      <br />
      <Grid item xs={12}>
        <Typography variant="subtitle2">Upload large image</Typography>
        <form onSubmit={handleSubmit1} className="form">
          <input type="file" />
          <button type="submit">Upload</button>
          {setshowmsg ? <p style={{ color: "red" }}>{send2}</p> : ""}
        </form>

        <TextField
              label="image url"
              value={imgUrl1}
              onChange={(e) => setImgUrl1(e.target.value)}
              required
              fullWidth
            />
      </Grid>

<br />
<br />
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Top information"
              value={t_info}
              onChange={(e) => setT_info(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Blog Id"
              value={caseId}
              onChange={(e) => setCaseId(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Blog name"
              value={b_name}
              onChange={(e) => setB_name(e.target.value)}
              required
              fullWidth
            />
            <br />
            <br />

            <JoditEditor
              ref={editor}
              value={content}
              // config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleAddDescription}>
              Add Description
            </Button>
          </Grid> */}
          {/* {caseStudies.map((description, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <TextField
                  label="Heading"
                  value={description.heading}
                  onChange={(e) => handleDescriptionChange(index, 'title', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Info"
                  value={description.info}
                  onChange={(e) => handleDescriptionChange(index, 'info', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => handleAddDetail(index)}>
                  Add Detail
                </Button>
              </Grid>
              {Array.isArray(description?.description) && description?.description.map((detail, detailIndex) => (
                <Grid container spacing={2} key={detailIndex}>
                  <Grid item xs={12}>
                    <TextField
                      label="Detail"
                      value={detail}
                      onChange={(e) =>
                        handleDescriptionChange(index, 'details', e.target.value, detailIndex)
                      }
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />

                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveDetail(index, detailIndex)}
                    >
                      Remove Detail
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <br />
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveDescription(index)}
                >
                  Remove Description
                </Button>
              </Grid>
            </React.Fragment>
          ))} */}
          <br />
        </Grid>
        <br />
        <br />
        {
          (edit)?(    <Button onClick={(e)=>SubmitEdit(e)} variant="contained" color="primary">
          Edit blog
        </Button>):(    <Button type="submit" variant="contained" color="primary">
          Upload blog
        </Button>)
        }
    
      </form>
      <br />
      <br />
      <table className="blog-table">
        <thead>
          <tr>
            <th  style={{width:"250px"}}>Title</th>
            <th style={{width:"50px"}}>blog ID</th>
            <th style={{width:"100px"}}>Delete</th>
            <th style={{width:"100px"}}>View</th>
            <th style={{width:"100px"}}>Status</th>
            <th style={{width:"100px"}}>Action</th>
            <th style={{width:"100px"}}>Edit</th>
         </tr>
        </thead>
        <tbody>
          {blogs.map((blog) => (
            <tr key={blog.blog_id}>
              <td>{blog.blog_tittle}</td>
              <td>{blog.blog_id}</td>
              <td>
                {" "}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => deleteBlog(e, blog.blog_id)}
                >
                  Remove Blog
                </Button>
              </td>
              <td>
                <Button  variant="contained"
                  color="primary">
                <NavLink style={{color:"white"}} to={`/resources/blog-detail?id=${blog.blog_name}`}>Preview</NavLink>

                </Button>
              </td>
              <td>{(blog.flag)?(
                <p > Live</p>
              ):(<p >Draft</p>)}</td>
              <td>{(blog.flag)?(
                <Button
                variant="contained"
                color="secondary"  onClick={(e) => makeFalse(e, blog.blog_id,blog.blog_tittle)}>Unpublish</Button>
              ):(<Button
                variant="contained"
                class="btn btn-success" onClick={(e) => maketrue(e, blog.blog_id,blog.blog_tittle)}>Publish</Button>)}</td>
      <td><Button
                variant="contained"
                color="primary"  onClick={(e) => Edit(e, blog.blog_id,blog.blog_tittle)}>Edit</Button></td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
      {/* <Button variant="danger" >Delete File</Button> */}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this file?
          {/* <input type="text" className="form-control mt-3" placeholder="Type 'CONFIRM' to delete" /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteClick}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Blogs;
