import React from 'react'

function Dashboard() {
  const documents = [
    {
      type: 'Blog',
      url: 'https://firebasestorage.googleapis.com/v0/b/sharpits-f9cb4.appspot.com/o/WhitepaperPDF%2FUpload_Blogs%20documentation.docx?alt=media&token=d3de7c9b-b7fc-4bc3-81a8-cee765c89fa9',
    },
    {
      type: 'Job',
      url: 'https://firebasestorage.googleapis.com/v0/b/sharpits-f9cb4.appspot.com/o/Doc%2FUpload_Jobs%5B1%5Dnew.docx?alt=media&token=18d7fdca-e051-4774-a58d-e2306659682d',
    },
    {
      type: 'Case Study',
      url: 'https://firebasestorage.googleapis.com/v0/b/sharpits-f9cb4.appspot.com/o/WhitepaperPDF%2FUpload%20Case%20documentation.docx?alt=media&token=da445f64-5c0e-445e-a119-ffad116df355',
    },
    {
      type: 'Whitepaper',
      url: 'https://firebasestorage.googleapis.com/v0/b/sharpits-f9cb4.appspot.com/o/Doc%2FUpload_Whitepaper%5B1%5D%20new.docx?alt=media&token=b57c4658-105c-41e4-8f51-afa3ea534aa8',
    },
    {
      type: 'Newsletter',
      url: 'https://firebasestorage.googleapis.com/v0/b/sharpits-f9cb4.appspot.com/o/Doc%2FNewsletter%5B1%5Dnew.docx?alt=media&token=69b3c253-06ab-45f6-bf51-0f20cbae1930',
    },
  ];
  return (
   <>
   <div className="admin-panel">
   <div className="header">
        <h1>Document Management</h1>
        <p>Manage various types of documents</p>
      </div>      <div className="document-grid">
        {documents.map((document, index) => (
          <div className="document-card" key={index}>
            <h3>{document.type}</h3>
            <a href={document.url} target="_blank" rel="noopener noreferrer">
              View Document
            </a>
          </div>
        ))}
      </div>
    </div>
   </>
  )
}

export default Dashboard