import React from 'react'
import styles from "./resources.module.scss";

import resources from "./images/resources.png";
import caseStudies from "./images/caseStudies.png";
import blogs from "./images/blogs.png";
import { NavLink } from 'react-router-dom';
import BI from './images/b_i.png'
const Resources = () => {
    return (
        <div className={styles.container}>
            <div className={styles.leftSection}>
                <NavLink  to="/resources/whitepaper" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={resources} alt="resources" />
                    </div>
                    <div className={styles.textContainer}>
                        Whitepapers
                    </div>
                </NavLink>
                <div className={styles.hr}></div>
                <NavLink  to="/resources/case-studies" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={caseStudies} alt="caseStudies" />
                    </div>
                    <div className={styles.textContainer}>
                        Case Studies
                    </div>
                </NavLink>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.rightSection}>
                <NavLink  to="/resources/blogs" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={blogs} alt="Blogs" />
                    </div>
                    <div className={styles.textContainer}>
                        Blogs
                    </div>
                </NavLink>
                <div className={styles.hr}></div>
                <NavLink  to="/resources/brochures" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={BI} alt="caseStudies" />
                    </div>
                    <div className={styles.textContainer}>
                        Brochures
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default Resources
