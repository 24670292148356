import React,{useState,useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import Logo from './Image/websiteLogo.png'
import './Admin.css'
import Axios from 'axios'
import { auth } from '../../firebase/config'
function Admin_login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const[mess,setmess]=useState('');
    const[showmes,setshowmess]=useState(false);
    const [pin,setpin]=useState(0);
    const [otpform,setotpform]=useState(false);
    const[inputOTp,setinputOTp]=useState();
    const [user,setuser]=useState('')
    const navigate = useNavigate();
   
    useEffect(() => {
        // Check if the user is already logged in (e.g., using local storage or an API call)
        const loggedInUser = localStorage.getItem('user');
        if (loggedInUser) {
            navigate('/admin');
        }else{
          navigate('/login')
        }
      }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
       
  auth.signInWithEmailAndPassword(username, password)
  .then(async(userCredential) => {
    // Login successful, do something with the user data
     setuser(userCredential.user) ;
    setpin()
    let Pin=Math.floor(1000 + Math.random() * 9000)
    setpin(Pin)
    setotpform(true);
    let value={
      pin:Pin,
      email:username,
    }
    await Axios.post(`https://us-central1-askmeidentity-website-ppe.cloudfunctions.net/as/sharpits/raisemail`, 
    value
   )
    // localStorage.setItem('user', JSON.stringify(user));
    // navigate('/admin') 
   // console.log("Logged in user:", user);
  })
  .catch((error) => {
    // Handle errors occurred during login
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error("Login error:", errorCode, errorMessage);
    setmess('Invalid Credentials')
    setshowmess(true)
  });
      };
  const checkotp =(e)=>{
  e.preventDefault();
  setmess('')
  if(pin==inputOTp){
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/admin')  
  }else{
    setmess('Invalid OTP')
    setshowmess(true)
  }
  }
  return (
    <>
    <div className='login_main_container'>
        <div className='login_main_container1'>
           
            <img style={{width:"60%"}} className='login_logo'  src={Logo} alt="" />
            <hr />
            {
(otpform)?(<>
 <input
        className='login_Input'
          type="number"
          id="username"
          placeholder='OTP'
          value={inputOTp}
          onChange={(event) => setinputOTp(event.target.value)}
        />
        <br/>
         {
        (showmes)?(
          <p style={{color:"red",float:"right",marginRight:"10px",marginLeft:"30px"}}>{mess}</p>
        ):('')
      }
      <button  className='login_btn' onClick={(e)=>checkotp(e)}>Submit</button>

</>)
:(<>
     <form onSubmit={handleSubmit}>
      <div>
       
        <input
        className='login_Input'
          type="text"
          id="username"
          placeholder='email'
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
      </div>
      <div>
      
        <input
          className='login_Input'
          type="password"
          placeholder='password'
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
      <br />
      {
        (showmes)?(
          <p style={{color:"red",float:"right",marginRight:"10px"}}>{mess}</p>
        ):('')
      }
      <button  className='login_btn' type="submit">sign in</button>
    </form>
          </>)            }
       
            
            

        </div>
    </div>
    </>
  )
}

export default Admin_login