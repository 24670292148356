import React from 'react'
import './copy.css'
function Copyright() {
  return (
    <div style={{backgroundColor:"#cadfff",display:"flex",justifyContent:"center",padding:"10px"}}>
            <div className='copyright_css' style={{textAlign:"center"}}>Copyright © 2024 Sharp IT Consulting Inc. All rights reserved</div>

    </div>
  )
}

export default Copyright