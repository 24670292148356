import React from 'react'
import styles from "./companies.module.scss";

import aboutUs from "./images/aboutUs.png";
import partners from "./images/partners.png";
import contactUs from "./images/contactUs.png";
import whySharpITS from "./images/whySharpITS.png";
import careers from "./images/careers.png";
import PP from './images/pp.png'
import { NavLink } from 'react-router-dom';

const Companies = () => {
    return (
        <div className={styles.container}>
            <div className={styles.leftSection}>
                <NavLink  to="/company/about-us" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={aboutUs} alt="about us" />
                    </div>
                    <div className={styles.textContainer}>
                        About us
                    </div>
                </NavLink>

                <div className={styles.hr}></div>

                <NavLink  to="/company/our-partners" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={partners} alt="partners" />
                    </div>
                    <div className={styles.textContainer}>
                        Partners
                    </div>
                </NavLink>

                <div className={styles.hr}></div>

                <NavLink  to="/company/contact-us" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={contactUs} alt="contact us" />
                    </div>
                    <div className={styles.textContainer}>
                        Contact Us
                    </div>
                </NavLink>
            </div>

            <div className={styles.divider}></div>

            <div className={styles.rightSection}>
                <NavLink  to="/company/why-sharpits" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={whySharpITS} alt="Why SharpITS" />
                    </div>
                    <div className={styles.textContainer}>
                        Why SharpITS
                    </div>
                </NavLink>

                <div className={styles.hr}></div>

                <NavLink  to="/company/careers" className={styles.row}>
                    <div className={styles.imageContainer}>
                        <img src={careers} alt="careers" />
                    </div>
                    <div className={styles.textContainer}>
                        Careers
                    </div>
                </NavLink>
                <div className={styles.hr}></div>

<NavLink  to="/company/privacy" className={styles.row}>
    <div className={styles.imageContainer}>
        <img src={PP} alt="careers" />
    </div>
    <div style={{paddingTop:"5px"}} className={styles.textContainer}>
        Privacy
    </div>
</NavLink>
            </div>
        </div>
    )
}

export default Companies
